import moment from 'moment';

export const showCAFClause = activity => {
  if (!!activity.activity_datetime_created) {
    var d1 = new Date(activity.activity_datetime_created);
    var d2 = new Date('2020-10-27 00:00:00');
    if (d1.getTime() < d2.getTime()) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const CAF_VERSION = {
  VI_BRANDING: 'VI_BRANDING',
  OLD_BRANDING: 'OLD_BRANDING',
  IDEA_BRANDING: 'IDEA_BRANDING',
};
export const getBranding = date => {
  let new_logo_date = moment('2020-09-08');
  let branding_date = moment(date);
  if (new_logo_date.diff(branding_date) > 0) {
    return CAF_VERSION.OLD_BRANDING;
  } else {
    return CAF_VERSION.VI_BRANDING;
  }
};

export const TERMS_VERSION = {
  NEW_TERMS: 'NEW_TERMS',
  OLD_TERMS: 'OLD_TERMS',
};
export const getVersionTerms = date => {
  let newTermData = moment('2021-07-21');
  let versionDate = moment(date);
  if (newTermData.diff(versionDate) > 0) {
    return TERMS_VERSION.OLD_TERMS;
  } else {
    return TERMS_VERSION.NEW_TERMS;
  }
};

export const encode = data => {
  let str = data.reduce(function (a, b) {
    return a + String.fromCharCode(b);
  }, '');
  return btoa(str).replace(/.{76}(?=.)/g, '$&\n');
};
